import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-99914c22"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home" }
const _hoisted_2 = { class: "error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Textbox = _resolveComponent("Textbox")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header, { title: "Happy Family!" }),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(error), 1)
      ]))
    }), 128)),
    _createVNode(_component_Textbox, {
      onEnter: _ctx.joinGame,
      textValue: _ctx.txtGameId,
      "onUpdate:textValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.txtGameId) = $event)),
      placeholderValue: "333B444"
    }, null, 8, ["onEnter", "textValue"]),
    _createVNode(_component_Button, {
      onClick: _ctx.joinGame,
      text: "Join Game"
    }, null, 8, ["onClick"])
  ]))
}